<template>
  <div class="detail-controlbar">
    <el-space size="large">
      <el-input
        placeholder="请输入邮箱或订单号"
        v-model="searchQuery"
        class="input-with-select"
        size="small"
      >
        <template #append>
          <el-button icon="el-icon-search" @click="search"></el-button>
        </template>
      </el-input>
      <el-popover placement="bottom" :width="350" trigger="manual" v-model:visible="visible">
        <template #reference>
          <el-button size="small" @click.stop="changeVisible">高级搜索</el-button>
        </template>
        <div class="filter-wrap" @click.stop>
          <el-form label-position="top" label-width="80px" :model="formData" size="small">
            <el-form-item label="邮箱或订单编号">
              <el-input v-model="formData.keyword" clearable></el-input>
            </el-form-item>
            <el-form-item label="支付时间区间">
              <el-date-picker
                v-model="formData.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :editable="false"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="邮件发送状态">
              <el-select v-model="formData.send_status" placeholder="请选择" :clearable="true">
                <el-option label="全部" :value="0"></el-option>
                <el-option label="已发送" :value="1"></el-option>
                <el-option label="未发送" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="优惠券使用码">
              <el-input v-model="formData.coupon_code" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <div class="confirm">
                <el-button type="primary" @click="superFilter">确定</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-popover>
    </el-space>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { parseTime } from '../../../../tools';
export default {
  props: ['query'],
  setup(props, { emit }) {
    const searchQuery = ref(props.search);
    const search = () => {
      emit('search', searchQuery.value);
    };
    watchEffect(() => {
      emit('queryChange', searchQuery.value);
    });

    const visible = ref(false);

    const changeVisible = () => {
      visible.value = !visible.value;
    };

    const formData = ref({
      keyword: '',
      time: '',
      send_status: '',
      coupon_code: '',
    });
    const superFilter = () => {
      console.log();
      const filterData = {
        ...formData.value,
        start_time: (formData.value.time && parseTime(formData.value.time[0])) || 0,
        end_time:
          (formData.value.time && parseTime(new Date(formData.value.time[1]).getTime())) || 0,
      };

      // 删除无用的 time 字段
      delete filterData.time;
      visible.value = false;
      emit('superFilter', filterData);
    };

    const closePopover = () => {
      visible.value = false;
    };
    return {
      search,
      searchQuery,
      visible,
      changeVisible,
      formData,
      superFilter,
      closePopover,
    };
  },
  mounted() {
    document.addEventListener('click', this.closePopover);
  },
  unmounted() {
    document.removeEventListener('click', this.closePopover);
  },
};
</script>

<style lang="scss" scoped>
.detail-controlbar {
  display: flex;
}
.el-form-item {
  margin-bottom: 6px;
}
// :deep(.el-range-editor--small.el-input__inner),
:deep(.el-select) {
  width: 100%;
}
.confirm {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  .el-button {
    width: 100px;
  }
}
</style>
