import request from '@/tools/request';

export const getInitData = (params, loading) => {
  return request({
    url: '/giftcard-config-giftorderlist.html',
    params,
    loading,
  });
};

export const sendNow = params => {
  return request({
    url: '/task.php?m=giftcard&c=Task&a=emailsend',
    params,
  });
};
